<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    ref="provider"
    v-slot="{ validate, errors, valid }"
  >
    <b-field
      v-bind="$attrs"
      :message="errors"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
    >
      <template #label>
        <slot name="label"></slot>
      </template>
      <b-upload
        :value="innerValue"
        v-bind="$attrs"
        @input="updateValue"
        :type="valid ? 'is-success' : errors[0] ? 'is-danger' : ''"
      >
        <slot>
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large"> </b-icon>
              </p>
              <p>{{ $t("upload.form.file-button-label") }}</p>
            </div>
          </section>
        </slot>
      </b-upload>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    async updateValue(newVal) {
      const { valid } = await this.$refs.provider.validate(newVal);

      if (valid) {
        this.innerValue = newVal;
        this.$emit("input", newVal);
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
<style lang="scss">
.upload .upload-draggable.is-danger {
  // border-color: #f14668;
  // background: rgba(241, 70, 104, 0.05);
  // has-background-success-light
  border-color: $danger;
  background-color: $danger-light;
}
.upload .upload-draggable.is-success {
  // border-color: #f14668;
  // background: rgba(241, 70, 104, 0.05);
  // has-background-success-light
  border-color: $success;
  background-color: $success-light;
}
</style>
