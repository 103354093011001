<template>
  <b-select-with-validation v-bind="$attrs" v-on="$listeners">
    <template #label>
      <slot name="label"></slot>
    </template>

    <option :value="null"></option>
    <option :value="true">{{ $t("true") }}</option>
    <option :value="false">{{ $t("false") }}</option>
  </b-select-with-validation>
</template>

<script>
import BSelectWithValidation from "./BSelectWithValidation.vue";
export default {
  components: { BSelectWithValidation },
  inheritAttrs: false,
};
</script>

<style></style>
