<template>
  <section class="">
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>

    <b-modal
      :active.sync="showFilterModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <template #default>
        <TableColumnFilter
          :columns="visibleFiltersModalColumn"
          :data="availableFilters"
          :draggable="false"
          @toggleVisibility="onFiltersToggleVisibility"
          @toggleVisibilityAll="onFiltersToggleVisibility"
        >
          <template #header>
            <p class="modal-card-title is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.header")
                  : $t("table.col-options.header")
              }}
            </p>
          </template>
          <template #beforeTable>
            <p class="is-size-5">
              {{
                isMobile
                  ? $t("table.col-options.mobile.col-filter-title")
                  : $t("table.col-options.col-filter-title")
              }}
            </p>
          </template>
        </TableColumnFilter>
      </template>
    </b-modal>

    <div class="container" style="padding-top: 1.5rem">
      <div class="buttons">
        <button
          v-if="isCreditEinvoice"
          type="button"
          class="button is-primary mb-5"
          @click="createSdIEInvoice"
        >
          {{ $t("upload.buttons.new_sdi_einvoice") }}
        </button>
        <button
          v-if="showUploadDummyBtn"
          type="button"
          class="button is-primary mb-5"
          @click="confirmUploadDummy"
        >
          {{ $t("upload.buttons.upload_dummy_doc") }}
        </button>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
      <form @submit.prevent="handleSubmit(upload)">
        <section class="section has-background-eurotext-light">
          <div class="container">
            <button
              class="button is-primary block"
              @click="showFilterModal = true"
            >
              {{ $t("buttons.hide_search_filters") }}
            </button>

            <template v-for="(group, index) in nullableGroups">
              <p
                :key="`p${index}`"
                v-if="index == 1"
                class="has-text-light block has-text-centered is-size-5"
              >
                {{ $t("upload.form.nullable_groups_header") }}
              </p>
              <div
                :key="index"
                class="box block has-background-eurotext"
                :class="{ 'has-border-danger': group.empty }"
              >
                <div class="columns is-multiline">
                  <div
                    v-for="prop in group.filters"
                    :key="prop.name"
                    class="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen custom-column-filter-align"
                    v-show="prop.visible"
                  >
                    <b-field v-if="isDate(prop.type)">
                      <template #label
                        ><span class="has-text-light">{{
                          $t(`docclass-properties.${prop.description}`)
                        }}</span></template
                      >
                      <b-datepicker
                        v-model="prop.value"
                        icon="calendar-today"
                        :first-day-of-week="1"
                        :required="!prop.nullable && prop.nullableGroup === 0"
                      ></b-datepicker>
                    </b-field>

                    <b-field v-else-if="isDateTime(prop.type)">
                      <template #label
                        ><span class="has-text-light">{{
                          $t(`docclass-properties.${prop.description}`)
                        }}</span></template
                      >
                      <b-datetimepicker
                        v-model="prop.value"
                        icon="calendar-today"
                        :first-day-of-week="1"
                        :required="!prop.nullable && prop.nullableGroup === 0"
                      >
                        <template #left>
                          <b-button
                            label="Now"
                            type="is-eurotext"
                            icon-left="clock"
                            @click="prop.value = new Date()"
                          />
                        </template>
                        <template #right>
                          <b-button
                            label="Clear"
                            type="is-danger"
                            icon-left="close"
                            @click="prop.value = null"
                          />
                        </template>
                      </b-datetimepicker>
                    </b-field>

                    <boolean-input
                      v-else-if="isBoolean(prop.type)"
                      expanded
                      v-model="prop.value"
                      style="width: 100%"
                    >
                      <template #label
                        ><span class="has-text-light">{{
                          $t(`docclass-properties.${prop.description}`)
                        }}</span></template
                      >
                    </boolean-input>

                    <b-input-with-validation
                      v-else
                      v-model="prop.value"
                      :vid="prop.name"
                      :name="$t(`docclass-properties.${prop.description}`)"
                      :rules="{
                        required: !prop.nullable && prop.nullableGroup === 0,
                        nullable_group: {
                          groupId: prop.nullableGroup,
                          groupProps: group.filters,
                          targetProp: prop,
                        },
                        max: { length: prop.dimension },
                        numeric: prop.type === 'numerico',
                      }"
                    >
                      <template #label
                        ><span class="has-text-light">{{
                          $t(`docclass-properties.${prop.description}`)
                        }}</span></template
                      ></b-input-with-validation
                    >
                  </div>
                </div>
              </div>
            </template>
          </div>
        </section>
        <!-- <b-field class="file">
        <b-upload v-model="file">
          <span class="button is-primary">
            <b-icon icon="upload"></b-icon>
            <span>{{ $t("upload.form.file-button-label") }}</span>
          </span>
        </b-upload>
        <div v-if="file">
          <span class="file-name">
            {{ file.name }}
          </span>
          <button class="button is-danger" @click="resetFile">
            <b-icon icon="delete"></b-icon>
          </button>
        </div>
      </b-field> -->
        <section class="section">
          <div class="container">
            <b-upload-with-validation
              v-model="file"
              rules="required"
              drag-drop
              expanded
            ></b-upload-with-validation>
            <!-- <b-field>
          <b-upload v-model="file" drag-drop expanded required>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>{{ $t("upload.form.file-button-label") }}</p>
              </div>
            </section>
          </b-upload>
        </b-field> -->

            <div class="tags has-addons are-medium" v-if="file">
              <span class="tag is-eurotext-light">
                {{ file.name }}
              </span>
              <!-- <button
          class="tag button is-delete"
          type="button"
          @click="resetFile"
        ></button> -->
              <a class="tag is-delete" @click="resetFile"></a>
            </div>
            <!-- <button @click="testFile">cliccami!</button> -->
            <button type="submit" class="button is-primary">
              <b-icon icon="check-outline"></b-icon>
              <span>{{ $t("upload.buttons.upload") }}</span>
            </button>
          </div>
        </section>
      </form>
    </ValidationObserver>
    <!-- </div> -->
  </section>
</template>

<script>
import { uaMixin } from "@/mixins";
import { ValidationObserver } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import BUploadWithValidation from "@/components/inputs/BUploadWithValidation.vue";
import BInputWithValidation from "../components/inputs/BInputWithValidation.vue";
import TableColumnFilter from "@/components/DocumentTable/TableColumnFilter";
import {
  getBase64FromFile,
  hexSHA256FromFile,
  createMetadata,
} from "../helpers";
import { checkType } from "../helpers/constants";
import { inputService, classService } from "../services";
import BooleanInput from "../components/inputs/BooleanInput.vue";
export default {
  components: {
    ValidationObserver,
    BUploadWithValidation,
    BInputWithValidation,
    TableColumnFilter,
    BooleanInput,
  },
  mixins: [uaMixin],
  name: "Upload",
  data() {
    return {
      file: null,
      classId: -1,
      // progressive: 0,
      properties: [],
      isUploading: false,
      isLoadingProperty: false,
      showFilterModal: false,
      visibleFiltersModalColumn: [],
    };
  },
  computed: {
    // ...mapState({
    //   isLoadingProperty: (state) => state.class.properties.isLoading,
    // }),
    ...mapGetters({
      getUploadableProperties: "class/getUploadableProperties",
      getClassNameById: "menu/getClassNameById",
      getClassByName: "menu/getClassByName",
    }),
    availableFilters() {
      // Do not let user decide on filter not nullable and not class id
      return this.nullableGroups[0].filters.filter(
        (f) => f.nullable && f.name !== "class_id"
      );
    },
    companyName: function () {
      return this.$route.params.company;
    },
    className: function () {
      return this.getClassNameById(this.classId, this.companyName);
    },
    isCreditEinvoice: function () {
      return this.getClassByName(this.className, this.companyName)
        .isCreditInvoice;
    },
    isDebitEinvoice: function () {
      return this.getClassByName(this.className, this.companyName)
        .isDebitInvoice;
    },
    isEinvoice: function () {
      return this.isCreditEinvoice || this.isDebitEinvoice;
    },
    isLoading: function () {
      return this.isLoadingProperty || this.isUploading;
    },
    nullableGroups() {
      const groups = _.groupBy(this.properties, "nullableGroup");
      return Object.keys(groups).map((g) => {
        return {
          filters: groups[g],
          style: {
            labelClass: "has-text-light",
            boxClass: "has-background-eurotext",
          },
          empty: false,
        };
      });
    },
    showUploadDummyBtn() {
      const confParam = this.$config.show_upload_dummy;
      if (confParam) {
        const confCompanies = Object.keys(confParam);
        for (let comp of confCompanies) {
          if (this.companyName === comp) {
            for (let confClass of confParam[comp]) {
              if (confClass === this.className) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    stateKey() {
      const view = this.$route.path.split("/").pop();
      return `${this.companyName}.${this.className}.${view}`;
    },
  },
  watch: {
    properties: function () {},
    // isLoadingProperty: function (newValue, oldValue) {
    //   if (!newValue && oldValue) {
    //     this.fetchLastUploadedProgressive();
    //   }
    // },
    getUploadableProperties: function (newValue) {
      // const properties = this.properties;
      // const result = newValue.map((val) => {
      //   const newProp = { ...val };
      //   let target = properties.find((prop) => {
      //     console.log(prop);
      //     return prop.name === val.name;
      //   });
      //   target ? (newProp.value = target.value) : (newProp.value = "");
      //   if (newProp.name === "input_date") newProp.value = new Date();
      //   return newProp;
      // });
      // this.properties = result;
      if (newValue && newValue.length > 0) this.updateProperties(newValue);
    },
  },
  methods: {
    ...mapActions("class", ["fetchProperties", "changeClass"]),
    getRandomHexColor(seed) {
      if (!seed) seed = Math.random();
      const bgColor =
        "#" +
        Math.floor(Math.abs(Math.sin(seed) * 16777215) % 16777215).toString(16);
      const color =
        bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      const r = parseInt(color.substring(0, 2), 16); // hexToR
      const g = parseInt(color.substring(2, 4), 16); // hexToG
      const b = parseInt(color.substring(4, 6), 16); // hexToB
      const darkColor = "rgba(#000, 0.7)";
      const lightColor = "#fff";
      // const r = Math.floor(Math.abs(Math.cos(seed)) * 256);
      // const g = Math.floor(Math.abs(Math.cos(seed)) * 256);
      // const b = Math.floor(Math.abs(Math.cos(seed)) * 256);
      let uicolors = [r / 255, g / 255, b / 255];
      let c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
      });
      const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
      const textColor = L > 0.179 ? darkColor : lightColor;
      // const eq = r * 0.299 + g * 0.587 + b * 0.114;
      // console.log(eq);
      // const textColor =
      //   r * 0.299 + g * 0.587 + b * 0.114 > 150 ? darkColor : lightColor;
      console.log(bgColor, r, g, b, L, textColor);
      return { color: textColor, "background-color": bgColor };
    },
    resetFile() {
      console.log(this.file);
      this.file = null;
    },
    createSdIEInvoice() {
      inputService.createSdIEInvoice(this.companyName, this.className).then();
    },
    updateProperties(newValue) {
      const properties = this.properties;
      if (newValue && newValue.length > 0) {
        const stateString = localStorage.getItem(this.stateKey);
        let visibleFiltersNames = null;
        if (stateString) {
          const restoredState = JSON.parse(stateString);
          visibleFiltersNames = restoredState.visible;
        }

        const result = newValue.map((val) => {
          const newProp = {
            ...val,
            label: this.$t(`docclass-properties.${val.description}`),
          };
          // properties is usually null because this method is called only once when filters are fetched at the moment.
          let target = properties.find((prop) => {
            return prop.name === val.name;
          });
          target ? (newProp.value = target.value) : (newProp.value = "");

          // Set filter visibility based on user preferences
          // Only filter with nullableGroup = 0 can be hidden
          if (newProp.nullableGroup === 0) {
            if (visibleFiltersNames) {
              newProp.visible = visibleFiltersNames.includes(newProp.name);
            } else {
              newProp.visible = true;
            }
          } else {
            newProp.visible = true;
          }

          if (
            checkType.isDate(newProp.type) ||
            checkType.isDateTime(newProp.type)
          ) {
            if (newProp.nullable) {
              // If it's nullable it is not required
              newProp.value = null;
            } else {
              newProp.value = new Date();
            }
          }
          if (newProp.name === "is_sdi_einvoice") {
            newProp.type = "alfanumerico";
            newProp.dimension = 5;
          }

          // Hide "class_id" filter to the user
          if (newProp.name === "class_id") {
            newProp.visible = false;
          }
          // if (newProp.name === "input_date") newProp.value = new Date();
          return newProp;
        });
        this.properties = result;
        this.fetchLastUploadedProgressive();
      }
    },
    setProgressive(newProgressive) {
      console.log("updating progressive with " + newProgressive);
      const prog = this.properties.find((prop) => prop.name === "progressive");
      if (prog) {
        prog.value = newProgressive;
      }
    },
    getProgressive() {
      const progr = this.properties.find((prop) => prop.name === "progressive");
      if (progr) {
        return parseInt(progr.value);
      } else {
        return null;
      }
      // return parseInt(
      //   this.properties.find((prop) => prop.name === "progressive").value
      // );
    },
    testFile() {
      const reader = new FileReader();
      reader.onload = function (e) {
        // The file's text will be printed here
        // console.log(e.target.result);
        const b64 = e.target.result.replace(/^data:.+;base64,/, "");
        // console.log(btoa(e.target.result));
        console.log(b64);
      };
      reader.readAsDataURL(this.file);
    },
    async upload() {
      console.log("uploading");
      for (let i = 1; i < this.nullableGroups.length; i++) {
        let g = this.nullableGroups[i];
        let valid = g.filters.every(
          (f) => f.nullable || f.value || f.value === false
        );
        g.empty = !valid;
      }

      // Check if there is at least one nullable group filled
      let allEmpty = false;
      if (this.nullableGroups.length > 1) {
        allEmpty = true;
        for (let i = 1; i < this.nullableGroups.length; i++) {
          if (!this.nullableGroups[i].empty) {
            allEmpty = false;
            break;
          }
        }
        if (allEmpty) {
          this.$buefy.toast.open({
            message: this.$t("upload.messages.fill_nullable_groups"),
            type: "is-danger",
            duration: 4000,
          });
          return;
        }
      }

      if (this.file) {
        try {
          this.isUploading = true;
          const b64 = await getBase64FromFile(this.file);
          const sha256 = await hexSHA256FromFile(this.file);
          const mime = this.file.type;
          const fileRes = await inputService.uploadFile(
            this.companyName,
            b64,
            mime,
            sha256
          );

          // If no document id is specified by the user, use the one form the server
          const docIdProp = this.properties.find(
            (p) => p.name === "document_id"
          );
          const docId =
            docIdProp && !!docIdProp.value
              ? docIdProp.value
              : fileRes["document_id"];

          const metaProp = this.properties.filter(
            (p) =>
              p.name !== "document_id" &&
              p.name !== "class_id" &&
              (!!p.value || p.value === false)
          );
          console.log(metaProp);

          metaProp.push({ name: "source_filename", value: this.file.name });

          const metadata = createMetadata(
            metaProp,
            this.className,
            this.file.type,
            fileRes["original_path"],
            docId
          );
          console.log(metadata);
          const response = await inputService.uploadMetadata(
            this.companyName,
            this.className,
            metadata,
            this.getProgressive(),
            parseInt(
              this.properties.find((val) => val.name === "this_year").value
            )
          );
          if (!response.error) {
            if (this.$config.remove_file_after_upload) {
              this.file = null;
            }
            // Notify user
            this.$buefy.toast.open({
              message: this.$t("upload.messages.upload_success"),
              type: "is-success",
              duration: 4000,
            });
            // Update progressive with new available value
            this.fetchLastUploadedProgressive();

            this.$refs.observer.reset();
            this.nullableGroups.forEach((g) => (g.empty = false));
          } else {
            const text = response.message
              ? response.message
              : this.$t("error.generic");
            // Notify user
            this.$buefy.toast.open({
              message: text,
              type: "is-danger",
              duration: 5000,
            });
          }
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            message: this.$t("error.generic"),
            type: "is-danger",
          });
        } finally {
          this.isUploading = false;
        }
      } else {
        this.$buefy.toast.open({
          message: this.$t("error.file-not-selected"),
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    confirmUploadDummy() {
      this.$buefy.dialog.confirm({
        message: this.$t("upload.confirm_upload_dummy_msg", {
          progressive: this.getProgressive(),
        }),
        cancelText: this.$t("no"),
        confirmText: this.$t("yes"),
        onConfirm: () => this.uploadDummy(),
      });
    },
    async uploadDummy() {
      console.debug("uploading dummy document");
      try {
        this.isUploading = true;
        const response = await inputService.uploadDummy(
          this.companyName,
          this.className,
          parseInt(this.getProgressive())
        );
        if (!response.error) {
          if (
            process.env.VUE_APP_REMOVE_FILE_AFTER_UPLOAD.toLowerCase == "true"
          ) {
            this.file = null;
          }
          // Notify user
          this.$buefy.toast.open({
            message: this.$t("upload.messages.upload_success"),
            type: "is-success",
            duration: 4000,
          });
          this.fetchLastUploadedProgressive();
        } else {
          const text = response.message
            ? response.message
            : this.$t("error.generic");
          // Notify user
          this.$buefy.toast.open({
            message: text,
            type: "is-danger",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          message: this.$t("error.generic"),
          type: "is-danger",
        });
      } finally {
        this.isUploading = false;
      }
    },
    fetchProperties() {
      this.isLoadingProperty = true;
      return classService
        .fetchDocumentProperties(this.companyName, this.classId, false, true)
        .then((data) => {
          this.updateProperties(data);
        })
        .finally(() => (this.isLoadingProperty = false));
    },
    async fetchLastUploadedProgressive() {
      const className = this.className;
      const company = this.companyName;
      const progressive = await classService.fetchLastUploadedProgressive(
        company,
        className
      );
      this.setProgressive(parseInt(progressive) + 1);
    },
    onFiltersToggleVisibility(visibleObjects) {
      let visibleFiltersNames = visibleObjects.map((obj) => obj.name);
      this.nullableGroups[0].filters.forEach((f) => {
        if (!visibleFiltersNames.includes(f.name)) f.visible = false;
      });
      this.storeState(visibleFiltersNames);
    },
    storeState(visibleFiltersNames) {
      let state = {};
      state.visible = visibleFiltersNames;
      if (Object.keys(state).length) {
        localStorage.setItem(this.stateKey, JSON.stringify(state));
      }
    },
    ...checkType,
  },
  created() {
    this.classId = Number(this.$route.query.class);
    this.visibleFiltersModalColumn = [
      {
        field: "label",
        label: this.$t("table.col-options.column-label"),
      },
    ];
  },
  mounted() {
    console.log("upload montato");
    this.fetchProperties();
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log("cambio rotta");
  //   // Fetch new properties and delete current documents if it's a new class
  //   if (to.path === from.path) {
  //     console.log("cambio classe");
  //     let newClassId = Number(to.query.class);
  //     this.changeClass(newClassId);
  //     this.classId = newClassId;
  //     this.fetchProperties(newClassId);
  //     this.fetchLastUploadedProgressive();
  //   }
  //   next();
  // },
};
</script>

<style lang="scss">
.has-border-danger {
  border: 2px solid $danger;
}
</style>
