<template>
  <ValidationProvider
    ref="validator"
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors }"
    slim
  >
    <b-field
      v-bind="fieldAttrs"
      :type="{ 'is-danger': errors[0] }"
      :message="errors"
    >
      <template #label>
        <slot name="label"></slot>
      </template>

      <b-input
        v-model="innerValue"
        v-bind="inputAttrs"
        @change.native="onInputChange"
        @input.native="onInput"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    isLowerCase: {
      type: Boolean,
      default: false,
    },
    fieldAttrs: {
      type: Object,
      default: () => {},
    },
    inputAttrs: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    setErrors(errors) {
      this.$refs.validator.setErrors(errors);
    },
    onInputChange(evt) {
      this.$emit("change", evt.target.value);
    },
    onInput(evt) {
      if (this.isLowerCase) {
        this.innerValue = evt.target.value.toLowerCase();
      }
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
